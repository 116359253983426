import { selectEmtpy, yearsOrderAsc } from "./hardcode";
import { objEquals } from "./helper";

export function validateEmail(value) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(value)) {
        return['Dirección de correo inválida']
    }
}

export function validateNotNull(value) {
    if (!value || value === '') {
        return ['Este campo es obligatorio*']
    }
}

export function validateIsAlpha(value) {
    const regex=/^[a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/;
    if (!regex.test(value)) {
        return['Este campo solo acepta caracteres alfabéticos']
    }
}

export function validatePhoneIsNumber(value) {
    const regex=/^[\d]+$/;
    if (!regex.test(value)) {
        return['Teléfono con formato no válido']
    }
}

export function validateIsChecked(value) {
    if(!value) {
        return['Este campo es obligatorio']
    }
}

export function validateMinLength(value) {
    if(!value  || value.length < 8) {
        return['La contraseña debe tener al menos 8 caractéres']
    }
}

export function validateMinPhoneLength(value) {
    if(!value  || value.length < 10) {
        return['Teléfono con formato no válido']
    }
}

export function validateLowerCharacter(value) {
    const regex=/[a-z]/;
    if(!value ||  !regex.test(value)) {
        return['La contraseña debe contener al menos una letra minúscula']
    }
}

export function validateUpperCase(value) {
    const regex=/[A-Z]/;
    if(!value ||  !regex.test(value)) {
        return['La contraseña debe contener al menos una letra mayúscula']
    }
}

export function validateNumber(value) {
    const regex=/\d/;
    if(!value ||  !regex.test(value)) {
        return['La contraseña debe contener al menos un número']
    }
}

export function validateSelectRangeDate(start, end, order) {
    if( start && end ) {
        let monthInvalid = true;
        let isYearValid = (order === yearsOrderAsc) ? true : ( end.yearId <= start.yearId );
        if( isYearValid ) {
            const isSameYear = start.yearId === end.yearId;
            monthInvalid = isSameYear && start.monthId > end.monthId;
        }
        if( monthInvalid ) {
            return['La fecha de término no puede ser anterior a la de inicio.']
        }
    }
}

export function validateTwoObjects(value1, value2, msgError) {
    if(objEquals(value1, value2)) {
        return[msgError];
    }
}

export function validateIsSelected(value) {
    const msgError = 'Este campo es obligatorio';
    return (!value.id && value.id !== 0) ? [msgError] : validateTwoObjects(value, selectEmtpy, msgError);
}