import { appApi, createJsonAccessToken } from './apiInstance';

const apiUrl = process.env.REACT_APP_GATEWAY_URL;

const mainContext = 'catalog';

export const getCatalog = (catalogId, page ,size, sort, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/${catalogId}/catalogItem?page=${page}&size=${size}${sort}`);

export const getSkillsCatalog = (page ,size, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/skills/candidates?page=${page}&size=${size}&sort=name&direction=ASC`);

export const getLanguageCatalog = accessToken => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/language`);

export const getLangLevelCatalog = accessToken => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/language/levels`);
