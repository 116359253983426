import { useContext, useEffect, useRef, useState } from 'react';
import styles from '../../../../components/modal/modal_base.module.scss';
import stylesC from '../../candidateInfo.module.scss';
import closeIcon from '../../../../../assets/icons/close.svg';
import { Checkbox } from '../../../../components/inputs/Checkbox';
import { InputText } from '../../../../components/inputs/InputText';
import { AppContext } from '../../../../../router/approuter';
import { CandidateContext } from '../../context';
import { TextArea } from '../../../../components/inputs/TextArea';
import { MONTHS, selectEmtpy, yearsHardCode, yearsOrderDesc } from '../../../../core/hardcode';
import { orderWorkExperiences, serializeDateString } from '../../../../core/helper';
import { validateIsSelected, validateNotNull, validateSelectRangeDate } from '../../../../core/validators';
import { SelectRange } from '../../../../components/inputs/SelectRange';
import Select, { SelectSortType } from '../../../../components/inputs/Select';

export const WorkExperienceModal = ({
    catSector
}) => {
    const appContext = useContext(AppContext);

    const ref = useRef();

    // const [closeable, setCloseable] = useState(false);

    // const onClickCloseModal = () => {
    //     if( closeable ) {
    //         appContext.setModalFlag(false);
    //     }
    //     setCloseable(true);
    // }

    // useEffect(() => {
    //     const checkIfClickedOutside = e => {
    //         if ( ref.current && !ref.current.contains(e.target) ) {
    //             onClickCloseModal();
    //         }
    //     }
    //     document.addEventListener("click", checkIfClickedOutside);
    //     return () => {
    //         document.removeEventListener("click", checkIfClickedOutside);
    //     }
    // }, [onClickCloseModal])

    const { 
        workExperience, 
        setWorkExperience,
        workExperiences, 
        setWorkExperiences 
    } = useContext( CandidateContext );

    const [catYears, setCatYears] = useState([]);
    const [errors, setErrors] = useState(false);

    const validationFields = {
        position: [validateNotNull],
        employer: [validateNotNull],
        fiMes: [validateIsSelected],
        fiAnio: [validateIsSelected],
        ftMes: [validateIsSelected],
        ftAnio: [validateIsSelected],
        description: [validateNotNull],
        sector: [validateIsSelected]
    }

    const validateRequest = () => {
        // creando el objeto de errores
        const errorObject = {};
        var helper;
        for(const field in validationFields) {
            for (const validator in validationFields[field]) {
                helper = validationFields[field][validator](workExperience[field])
                if (helper) {
                    errorObject[field] = helper
                }
            }
        }

        if( !workExperience.stillWork 
            && !validateIsSelected(workExperience.fiMes) && !validateIsSelected(workExperience.fiAnio)
            && !validateIsSelected(workExperience.ftMes) && !validateIsSelected(workExperience.ftAnio) ) {
            const start = {
                yearId: workExperience.fiAnio.id,
                monthId: workExperience.fiMes.id
            }
            const end = {
                yearId: workExperience.ftAnio.id,
                monthId: workExperience.ftMes.id
            }
            const compareDates = validateSelectRangeDate(start, end, yearsOrderDesc);
            if( compareDates ) {
                errorObject['ftMes'] = compareDates
                errorObject['ftAnio'] = compareDates
            }
        } else if( workExperience.stillWork) {
            delete errorObject.ftMes;
            delete errorObject.ftAnio;
        }

        return errorObject
    }

    useEffect(() => {
        setCatYears(yearsHardCode(yearsOrderDesc));
    }, [])
    
    const handleOnClickAdd = () => {
        const validationErrors = validateRequest();
        if (Object.keys(validationErrors).length === 0) {
            const startDate = (workExperience.fiMes && workExperience.fiAnio && workExperience.fiMes.id !== 0 && workExperience.fiAnio.id !== 0) 
                ? serializeDateString(workExperience.fiMes.id, workExperience.fiAnio.description) : null;
            const endDate = (workExperience.ftMes && workExperience.ftAnio && workExperience.ftMes.id !== 0 && workExperience.ftAnio.id !== 0) 
                ? serializeDateString(workExperience.ftMes.id, workExperience.ftAnio.description) : null;
            const workExperienceTmp = {
                ...workExperience,
                'startDate': startDate,
                'endDate': endDate,
            }
            setWorkExperience(workExperienceTmp);
            const weExist = workExperiences[workExperience.index];
            let workExperiencesTmp = [...workExperiences];
            if( weExist ) {
                workExperiencesTmp[workExperience.index] = workExperienceTmp;
            } else {
                workExperiencesTmp.unshift(workExperienceTmp)
            }
            const workExperiencesCurrents = workExperiencesTmp.filter( we => !we.endDate );
            const workExperiencesLast = workExperiencesTmp.filter( we => we.endDate );
            workExperiencesTmp = [ ...orderWorkExperiences(workExperiencesCurrents) ]
            workExperiencesTmp = [ ...workExperiencesTmp, ...orderWorkExperiences(workExperiencesLast) ]
            setWorkExperiences(workExperiencesTmp);
            appContext.setModalFlag(false)
        } else {
            setErrors(validationErrors)
        }
    }

    const updateWorkExperience = (property, value) => {
        setWorkExperience({ ...workExperience, [property]: value});
    }

    const handleCheckworkYet = (property, value) => {
        if( value ) {
            setWorkExperience({
                ...workExperience,
                [property]: value,
                ftMes: selectEmtpy,
                ftAnio: selectEmtpy,
            });
        } else {
            setWorkExperience({ ...workExperience, [property]: value});
        }
    }

    const updateSector = (property, value) => {
        setWorkExperience({ ...workExperience, ["sector"]: value, ["sectorId"]: value.id, ["sectorName"]: value.description});
    }

    return (
        <div className={ styles.modal_container } ref={ ref }>
            <div className={ styles.close }>
                <img src={ closeIcon } alt="" onClick={() => appContext.setModalFlag(false)} />
            </div>
            <p className={ styles.title } >{`${ workExperience.id && workExperience.id > 0 ? 'Editar' : 'Agregar'} Experiencia Profesional`}</p>
            <div className={ styles.form_container }>
                <div className={ styles.form_wrapper }>
                    <div className={ styles.form_columns }>
                        <InputText 
                            cssContainer={ styles.form_column } 
                            name="position"
                            label={ 'Posición*' } 
                            value={ workExperience.position } 
                            onChange={ updateWorkExperience } 
                            error={ Object.keys(errors).includes("position") ? errors.position[0] : '' }
                        />
                        <InputText 
                            cssContainer={ styles.form_column }
                            name="employer"
                            label={ 'Empleador*' }
                            value={ workExperience.employer }
                            onChange={ updateWorkExperience }
                            error={ Object.keys(errors).includes("employer") ? errors.employer[0] : '' } />
                    </div>
                    <div className={ styles.form_columns}>
                        <div className={ styles.form_column }>
                            <div className={ styles.custom_select }>
                                <p className={ styles.custom_title }>Sector*</p>
                                <Select
                                    name="sector"
                                    placeholder="Selecciona un tipo"
                                    value={ workExperience.sector }
                                    onChange={ updateSector } 
                                    options={ catSector }
                                    error= { Object.keys(errors).includes("sector") ? errors.sector[0] : '' }
                                    sort={ SelectSortType.WE_SECTOR }
                                />
                                {
                                    Object.keys(errors).includes("sector") && (
                                        <p className={ stylesC.error_message_font }>
                                            { errors.sector ? `${errors.sector[0]}*` : '' }
                                        </p>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className={ styles.form_row}>
                        <TextArea
                            cssSubtitle={ styles.subtitle }
                            cssCounter={ styles.subtitle_left }
                            name={ 'description' }
                            label={ 'Actividades*' }
                            sublabel={ 'Describe un resumen de las actividades que realizaste en esta posición.' }
                            maxLength={ 1500 }
                            value={ workExperience.description }
                            onChange={ updateWorkExperience }
                            error={ Object.keys(errors).includes("description") ? errors.description[0] : '' }
                        />
                    </div>
                    <p className={ styles.form_subtitle }>Periodo</p>
                    <div className={ styles.form_columns }>
                        <div className={ styles.form_column }>
                            <label>Fecha de inicio*</label>
                            <SelectRange 
                                prop1={{
                                    name: 'fiMes',
                                    onChange: updateWorkExperience,
                                    options: MONTHS,
                                    placeholder: 'Mes',
                                    value: workExperience.fiMes,
                                    error: Object.keys(errors).includes("fiMes") ? errors.fiMes[0] : ''
                                }}
                                prop2={{
                                    name: 'fiAnio',
                                    onChange: updateWorkExperience,
                                    options: catYears,
                                    placeholder: 'Año',
                                    value: workExperience.fiAnio,
                                    error: Object.keys(errors).includes("fiAnio") ? errors.fiAnio[0] : ''
                                }}
                            />
                        </div>
                        <div className={ styles.form_column }>
                            <label>Fecha de t&eacute;rmino*</label>
                            <SelectRange 
                                prop1={{
                                    name: 'ftMes',
                                    onChange: updateWorkExperience,
                                    options: MONTHS,
                                    placeholder: 'Mes',
                                    value: workExperience.ftMes,
                                    error: (!workExperience.stillWork && Object.keys(errors).includes("ftMes")) ? errors.ftMes[0] : ''
                                }}
                                prop2={{
                                    name: 'ftAnio',
                                    onChange: updateWorkExperience,
                                    options: catYears,
                                    placeholder: 'Año',
                                    value: workExperience.ftAnio,
                                    error: (!workExperience.stillWork && Object.keys(errors).includes("ftAnio") ) ? errors.ftAnio[0]  : ''
                                }}
                                disabled={ workExperience.stillWork }
                            />
                            <Checkbox
                                cssLabel = { styles.subtitle } 
                                name="stillWork"
                                label = { 'Aún trabajo aquí' }
                                value={ workExperience.stillWork }
                                onClick={ handleCheckworkYet } />
                        </div>
                    </div>
                </div>
                <div className={ styles.buttons }>
                    <button className={ styles.cancel_button + " " + styles.mobile } onClick={() => appContext.setModalFlag(false)}>Cancelar</button>
                    <button onClick={ () => handleOnClickAdd() }>{ workExperience.id && workExperience.id > 0 ? 'Guardar' : 'Agregar'}</button>
                </div>
            </div>
        </div>
    )
}