import { 
    useState,
    useEffect,
    createContext } from 'react';
import { 
    Route,
    Routes,
    useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import notificationStyles from '../app/components/notification/notification.module.scss';

import SignUp from '../app/pages/signup/signup';
import LogIn from '../app/pages/login/login';
import CandidateInfo from '../app/pages/candidates/candidateInfo';
import PasswordRecovery from '../app/pages/password_recovery/password_recovery';
import { CandidateProvider } from '../app/pages/candidates/context';
import Notification from '../app/components/notification/notification';
import ModalBase from '../app/components/modal/modal_base';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback, logError } from '../app/components/error/ErrorHandler';

export const AppContext = createContext();

const AppRouter = () => {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    const [loadingData, setLoadingData] = useState({ flag: false, loadingLabel: '' });
    const [modalFlag, setModalFlag] = useState(false);
    const [modalContent, setModalContent] = useState(undefined);
    const [modalFull, setModalFull] = useState(false);
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('userInfo')));
    const [signUpInfo, setSignUpInfo] = useState({})
    const [stepSignUpIndex, setStepSignUpIndex] = useState(0);
    const [fromInfo, setFromInfo] = useState(false);

    const showLoading = (flag, loadingLabel, overflowClass) => {
        if( flag ) {
            document.body.classList.add( overflowClass );
        } else {
            document.body.classList.remove( overflowClass );
        }
        setLoadingData({ 'flag': flag, 'loadingLabel': loadingLabel });
    }

    const navigate = useNavigate();

    const logOutCookieName = process.env.REACT_APP_LOGOUT_COOKIE_NAME;
    const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN;
    const sessionCookie = process.env.REACT_APP_COOKIE_NAME;

    const [notificationData, setNotificationData] = useState({
        notificationStyle: notificationStyles.notification_wrapper
    });

    const notification = <Notification data={ notificationData } />;
    const modal = <ModalBase />

    const displayNotification = (message, isErrorConection, display) => {
        window.scrollTo(0, 0);
        const styleShow = isErrorConection ? `${notificationStyles.notification_wrapper_error} ${notificationStyles.active}` : `${notificationStyles.notification_wrapper} ${notificationStyles.active}`;
        setNotificationData({
            notificationStyle: styleShow,
            message: message,
            isDisplay: display && display === 'hide'  ? false : true,
            isErrorConection: isErrorConection
        });

        if( !isErrorConection ) {
            setTimeout(() => {
                setNotificationData({
                    notificationStyle: notificationStyles.notification_wrapper,
                    message: message,
                    isDisplay: false
                })
            }, 5000);
        }
    }

    useEffect(() => {
        const cookies = document.cookie.split(';');
        const jobBoardCookies = cookies.filter(value => (value.includes(logOutCookieName)))
        if (jobBoardCookies.length > 0 || ( userData && !userData.hasOwnProperty('userProfile'))) {
            localStorage.removeItem('userInfo');
            setUserData(null);
            navigate("/login");
            document.cookie = logOutCookieName + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=" + cookieDomain + ";path=/;";
            document.cookie = sessionCookie + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=" + cookieDomain + ";path=/;";
        }
    }, [])

    return (
        <AppContext.Provider value={ {
            modalFlag,
            setModalFlag,
            loadingData,
            showLoading,
            modalContent,
            modal,
            setModalContent,
            navigate,
            displayNotification,
            notification,
            userData,
            setUserData,
            isDesktopOrLaptop,
            signUpInfo,
            setSignUpInfo,
            stepSignUpIndex,
            setStepSignUpIndex,
            fromInfo,
            setFromInfo,
            modalFull,
            setModalFull
            } }>
            <CandidateProvider>
                <ErrorBoundary FallbackComponent={ ErrorFallback } onError={logError}>
                    <Routes>
                        <Route path="/" element={ <LogIn /> } />
                        <Route path="login" element={ <LogIn /> } />
                        <Route path="signup" element={ <SignUp /> } />
                        <Route path="password-recovery" element={ <PasswordRecovery /> } />
                        <Route path="candidate-info" element={ <CandidateInfo /> } />
                    </Routes>
                </ErrorBoundary>
            </CandidateProvider>
        </AppContext.Provider>
    )
}
export default AppRouter;