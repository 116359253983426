import { appApi, createJsonAccessToken } from './apiInstance';

const apiUrl = process.env.REACT_APP_GATEWAY_URL;

const mainContext = 'talent';

const headerMultiFormData = accessToken => {
    return {
        ...createJsonAccessToken(accessToken),
        contentType: 'multipart/form-data'
    }
};

export const testConnection = accessToken => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/test`);

export const getTalent = (talentId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/${talentId}`);

export const saveTalent = (request, accessToken) => appApi(apiUrl, headerMultiFormData(accessToken)).post(`${mainContext}/new`, request);

export const updateTalent = (request, accessToken) => appApi(apiUrl, headerMultiFormData(accessToken)).put(`${mainContext}/edit?sectionId=1`, request);