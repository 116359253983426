export const types = {
    setPersonalInfo: '[Candidate] setPersonalInfo',
    setWorkExperience: '[Candidate] setWorkExperience',
    setAcademic: '[Candidate] setAcademic',
    setCertification: '[Candidate] setCertification',
    setLanguage: '[Candidate] setLanguage',
    setSkill: '[Candidate] setSkill',
    setWorkExperiences: '[Candidate] setWorkExperiences',
    setAcademics: '[Candidate] setAcademics',
    setCertifications: '[Candidate] setCertifications',
    setLanguages: '[Candidate] setLanguages',
    setLanguagesCache: '[Candidate] setLanguagesCache',
    setLanguageErrors: '[Candidate] setLanguageErrors',
    setSkills: '[Candidate] setSkills',
    setSkillErrors: '[Candidate]: setSkillErrors',
    setSkillsCache: '[Candidate]: setSkillsCache'
}