import PropTypes from 'prop-types';
import styles from './input.module.scss';
import checkboxIcon from '../../../assets/icons/checkbox_uncheck.svg'
import checkboxCheckedIcon from '../../../assets/icons/checkbox_checked.svg'

export const Checkbox = ({
    cssLabel,
    name,
	value,
	label,
	onClick,
}) => {

    const handleOnCheck = checkValue => {
        onClick(name, checkValue);
    }

    const renderCheckIcon = () => {
        return value ? 
            (<img src={ checkboxCheckedIcon } alt="" onClick={ () => handleOnCheck(false) } />) 
            : (<img src={ checkboxIcon } alt="" onClick={ () => handleOnCheck(true) }  />);
    }

    return (
        <div className={ styles.check_container }>
            { renderCheckIcon() }
            <label className={ cssLabel }>
                { label }
            </label>
        </div>
    )
}

Checkbox.propTypes = {
	value: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};