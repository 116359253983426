import { useEffect, useContext } from 'react';

import styles from './signup.module.scss';
import stylesCandidate from '../candidates/candidateInfo.module.scss';

import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';

import { AppContext } from '../../../router/approuter';

import SignUpForm from './components/signUpForm';
import VerificationCode from './components/verificationCode';
import ConfirmationMessage from './components/confirmationMessage';


const SignUp = () => {
    const appContext = useContext(AppContext)

    function handleCancelValidation() {
        appContext.setSignUpInfo({});
        appContext.setStepSignUpIndex(0);
    }

    const pageSteps = {
        0: <SignUpForm
            signUpInfo={ appContext.signUpInfo }
            setSignUpInfo={ appContext.setSignUpInfo }
            setStepIndex={ appContext.setStepSignUpIndex }
            appContext={ appContext } />,
        1: <VerificationCode
            signUpInfo={ appContext.signUpInfo }
            setStepIndex={ appContext.setStepSignUpIndex }
            handleCancelValidation={ handleCancelValidation }
            appContext={ appContext } />,
        2: <ConfirmationMessage
            signUpInfo={ appContext.signUpInfo }
            appContext={ appContext } />
    }

    
    const codeRecoveryErrorMessage = () => {
        return (
            <div className={ styles.error_modal }>
                <div className={ styles.wrapper }>
                    <p className={ styles.title }>Has superado el l&iacute;mite de envios.</p>
                    <p>Si necesitas reenviarlo, por favor intentalo en una hora.</p>
                    <div className={ styles.buttons }>
                        <button onClick={() => appContext.setModalFlag(false)}>Entendido</button>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        appContext.setModalContent(codeRecoveryErrorMessage);
    }, [])

    const renderLoading = () => appContext.loadingData.flag && (
        <div className={ stylesCandidate.loading_container }>
            <div className={ stylesCandidate.spinner_container }>
                <div className={ stylesCandidate.spinner }></div>
                <p>{ appContext.loadingData.loadingLabel }</p>
            </div>
        </div>
    )

    return  (
        <div className = { 
                appContext.modalFlag ? 
                ( ( appContext.isDesktopOrLaptop ? styles.modal_wrapper : styles.modal_wrapper_mobile ) + " " + styles.modal_active) 
                : ( appContext.isDesktopOrLaptop ? styles.modal_wrapper : styles.modal_wrapper_mobile ) 
        }>
            { renderLoading() }
            { appContext.modalFlag && appContext.modal }
            <Header
                userData={ appContext.userData }
                navigate={ appContext.navigate }
                setUserData={ appContext.setUserData }
                modalFlag={ appContext.modalFlag }
                setModalFlag={ appContext.setModalFlag }
                setModalContent={ appContext.setModalContent }
                stepIndex={ appContext.stepIndex }/>
            <div className={ appContext.isDesktopOrLaptop ? styles.signup : styles.signup_mobile }>
                { appContext.notification }
                <div className={ styles.wrapper }>
                    {
                        pageSteps[appContext.stepSignUpIndex]
                    }
                </div>
            </div>
            { appContext.isDesktopOrLaptop && <Footer /> }
        </div>
    )

}

export default SignUp