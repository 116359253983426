import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../../router/approuter';
import styles from '../../candidateInfo.module.scss';
import { getLangLevelCatalog } from '../../../../api/catalogClient';

export const LanguagesLevel = ({
    renderBackButton
}) => {

    const appContext = useContext(AppContext);
    const [content, setContent] = useState([
        { title: 'Básico', items: [ { level: 'A1' }, { level: 'A2' } ] },
        { title: 'Intermedio', items: [ { level: 'B1' }, { level: 'B2' } ] },
        { title: 'Avanzado', items: [ { level: 'C1' }, { level: 'C2' } ] }
    ]);

    const getName = (catLevels, name) => {
        const desc = catLevels.find( c => c.name === name).description;
        return desc.substring(0, desc.indexOf(':'));
    }

    const getDescription = (catLevels, name) => {
        const desc = catLevels.find( c => c.name === name).description;
        return desc.substring(desc.indexOf(':')+1);
    }

    const loadCatLangLevels = () => {
        getLangLevelCatalog(appContext.userData.token).then((response) => {
            if( response.status === 200 && response.data && response.data.code === 202 && response.data.result && Array.isArray(response.data.result) ) {
                const catLevels = response.data.result;
                const contentTmp = [ ...content ];
                contentTmp.map(c => {
                    c.items.map( i => {
                        const levelName = getName(catLevels, i.level);
                        const levelDesc = getDescription(catLevels, i.level);
                        i.title = levelName;
                        i.desciption = levelDesc;
                    })
                });
                setContent(contentTmp);
            }
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        loadCatLangLevels();
    }, [])

    const renderItems = ({ items }) => items.map( (i, index) => {
        return (
            <div key={ index } className={ styles.information_container }>
                <div className={ styles.information_level_container }>
                    <p className={ styles.information_level }>{ i.level }</p>
                </div>
                <div>
                    <p className={ styles.information_title }>{ i.title }</p>
                    <p className={ styles.information_desc }>{ i.desciption }</p>
                </div>
            </div>
        )
    } )

    const renderContent = () => content.map( ( c, index ) => {
        return (
            <div key={ index } className={ styles.form_information }>
                <div className={ styles.title }>{ c.title }</div>
                { renderItems(c) }
            </div>
        )
    } )

    const title = 'Niveles de idioma';
    const renderTitle = () => (<p className={ styles.title }>{ title }</p>);

    return (
        <div className={ styles.form_container }>
            { appContext.isDesktopOrLaptop ? renderTitle() : renderBackButton(title, 4) }
            { renderContent() }
        </div>
    )
}
