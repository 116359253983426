
import styles from './candidateInfo.module.scss';

import {ReactComponent as FacebookIcon } from '../../../assets/icons/facebook_icon.svg';
import {ReactComponent as InstagramIcon } from '../../../assets/icons/instagram_icon.svg';
import {ReactComponent as LinkedInIcon } from '../../../assets/icons/linkedin_icon.svg';
import {ReactComponent as TwitterIcon } from '../../../assets/icons/x_logo.svg';

import messageImg from '../../../assets/img/profile_created.png'

const ConfirmationMessage = (
    {
        personalInfo,
        setStepIndex
    }
) => {

    const careersUrl = process.env.REACT_APP_CAREERS_LANDING;

    const handleVacancyListClick = () => {
        window.location.href = careersUrl + "open-positions";
        return null;
    }

    const handleMyProfileClick = () => {
        setStepIndex(6);
        window.location.href = "/candidate-info?careersRedirect=True";
    }

    return (
        <div className={ styles.message_wrapper }>
            <div className={ styles.title_wrapper }>
                <p>{ "¡Gracias, " + personalInfo.firstName + "! Tu perfil ha sido registrado correctamente." }</p>
            </div>
            <div className={ styles.message_content }>
                <p style={{ fontWeight: '400' }}>Ahora puedes postularte de forma &aacute;gil y r&aacute;pida a cualquiera de las vacantes disponibles que tenemos para ti.</p>
                <div className={ styles.buttons }>
                    <button onClick={() => handleVacancyListClick() }>Ver vacantes disponibles</button>
                    <button className={ styles.iddle } onClick={() => handleMyProfileClick() }>Ver mi perfil</button>
                </div>
                <div className={ styles.links }>
                    <p style={{ fontWeight: '700' }}>Te invitamos a seguirnos en nuestras redes sociales.</p>
                    <div className={ styles.links_row}>
                        <div className={ styles.icon_container}>
                            <a href="https://www.facebook.com/mystratis" target="_blank">
                                <FacebookIcon />
                            </a>
                        </div>
                        <div className={ styles.icon_container}>
                            <a href="https://www.instagram.com/my.stratis/?hl=es-la" target="_blank">
                                <InstagramIcon />
                            </a>
                        </div>
                        <div className={ styles.icon_container}>
                            <a href="https://www.linkedin.com/company/mystratis/" target="_blank">
                                <LinkedInIcon />
                            </a>
                        </div>
                        <div className={ styles.icon_container}>
                            <a href="https://twitter.com/mystratis" target="_blank">
                                <TwitterIcon />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <img src={ messageImg } alt="" />
        </div>
    )
}

export default ConfirmationMessage;