import { useReducer } from "react"

import { types } from './types';
import { CandidateContext } from "./CandidateContext";
import { candidateReducer } from "./candidateReducer";
import { selectEmtpy, selectSearcherEmtpy } from "../../../core/hardcode";

const workExperienceEmpty = {
    id: 0,
    position: '',
    employer: '',
    description: '',
    stillWork: false,
    startDate: null,
    endDate: null,
    fiMes: selectEmtpy,
    fiAnio: selectEmtpy,
    ftMes: selectEmtpy,
    ftAnio: selectEmtpy,
    period: '',
    sectorId: null,
    sectorName: '',
    sector: selectEmtpy
}

const academicEmpty = {
    courseId: 0,
    name: '',
    school: '',
    stillStudy: false,
    endDate: null,
    fIniMes: selectEmtpy,
    fIniAnio: selectEmtpy,
    fTerMes: selectEmtpy,
    fTerAnio: selectEmtpy,
    type: selectEmtpy,
    certificateNumber: null,
    period: ''
}

const courseEmpty = {
    courseId: 0,
    name: '',
    school: '',
    stillStudy: false,
    validity: false,
    endDate: null,
    validityDate: null,
    fiMes: selectEmtpy,
    fiAnio: selectEmtpy,
    ftMes: selectEmtpy,
    ftAnio: selectEmtpy,
    fvMes: selectEmtpy,
    fvAnio: selectEmtpy,
    period: '',
    type: selectEmtpy,
    certificateNumber: ''
}

const languageEmpty = {
    id: 0,
    languageId: 0,
    langLevelId: 1,
    language: selectEmtpy,
    langLevel: { id: 1, description: 'Básico' }
}

const skillEmpty = {
    id: 0,
    tagId: 0,
    name: '',
    description: '',
    categoryId: 0,
    skillLevelPercentage: 0,
    tag: selectSearcherEmtpy
}

const init = () => {
    return {
        personalInfoCache: {},
        workExperience: workExperienceEmpty,
        academic: academicEmpty,
        certification: courseEmpty,
        language: languageEmpty,
        languageErrors: [],
        skill: skillEmpty,
        skillErrors: {},
        workExperiences: [],
        academics: [],
        certifications: [],
        languages: [],
        languagesCache: [],
        skills: [],
        skillsCache: []
    }
}

export const CandidateProvider = ({ children }) => {

    const [candidateState, dispatch] = useReducer(candidateReducer, {}, init);

    const setPersonalInfoCache = ( personalInfo = {} ) => {
        dispatch({ type: types.setPersonalInfo, payload: personalInfo });
    }

    const setWorkExperience = ( workExperience = {} ) => {
        const endDateStr = workExperience.stillWork ? 'Actualidad' : `${workExperience.ftMes.description} ${workExperience.ftAnio.description}`;
        const period = `${workExperience.fiMes.description} ${workExperience.fiAnio.description} - ${endDateStr}`;
        dispatch({ 
            type: types.setWorkExperience, 
            payload: {
                ...workExperience,
                period: period
            }
        });
    }

    const cleanWorkExperience = ( ) => {
        dispatch({ 
            type: types.setWorkExperience, 
            payload: workExperienceEmpty
        });
    }

    const setAcademic = ( academic = {} ) => {
        const dateStr = academic.stillStudy ? 'Estudiante' : `Fecha de graduación: ${academic.fIniMes.description} ${academic.fIniAnio.description}`;
        dispatch({
            type: types.setAcademic, 
            payload: {
                ...academic,
                period: dateStr
            }
        });
    }

    const cleanAcademic = ( ) => {
        dispatch({ 
            type: types.setAcademic, 
            payload: academicEmpty
        });
    }

    const setCertification = ( certification = {} ) => {
        const dateStr = certification.stillStudy ? 'Sin vigencia' : `Vigencia: ${certification.ftMes.description} ${certification.ftAnio.description}`;
        dispatch({ 
            type: types.setCertification, 
            payload: {
                ...certification,
                period: dateStr
            }
        });
    }

    const cleanCertification = ( ) => {
        dispatch({ 
            type: types.setCertification, 
            payload: courseEmpty
        });
    }

    const setLanguage = ( language = {} ) => {
        dispatch({
            type: types.setLanguage,
            payload: language
        });
    }

    const cleanLanguage = ( ) => {
        dispatch({
            type: types.setLanguage,
            payload: languageEmpty
        });
    }

    const setLanguageErrors = languageErrors => {
        dispatch({
            type: types.setLanguageErrors,
            payload: languageErrors
        })
    }

    const setSkill = ( skill = {} ) => {
        dispatch({
            type: types.setSkill,
            payload: skill
        });
    }

    const cleanSkill = categoryId => {
        dispatch({
            type: types.setSkill,
            payload: { ...skillEmpty, 'categoryId': categoryId }
        });
    }

    const setSkillErrors = skillErrors => {
        dispatch({
            type: types.setSkillErrors,
            payload: skillErrors
        })
    }

    const setWorkExperiences = ( workExperiences = [] ) => {
        dispatch({ type: types.setWorkExperiences, payload: workExperiences });
    }

    const setAcademics = ( academics = [] ) => {
        dispatch({ type: types.setAcademics, payload: academics });
    }

    const setCertifications = ( certifications = [] ) => {
        dispatch({ type: types.setCertifications, payload: certifications });
    }

    const setLanguages = ( languages = [] ) => {
        dispatch({ type: types.setLanguages, payload: languages });
    }

    const setLanguagesCache = ( languages = [] ) => {
        dispatch({ type: types.setLanguagesCache, payload: languages });
    }

    const setSkills = ( skills = [] ) => {
        dispatch({ type: types.setSkills, payload: skills });
    }

    const setSkillsCache = ( skillsCache = [] ) => {
        dispatch({ type: types.setSkillsCache, payload: skillsCache });
    }

    return (
        <CandidateContext.Provider value={{
            ...candidateState,
            setPersonalInfoCache,
            setWorkExperience,
            cleanWorkExperience,
            setAcademic,
            cleanAcademic,
            setCertification,
            cleanCertification,
            setLanguage,
            cleanLanguage,
            setSkill,
            cleanSkill,
            setWorkExperiences,
            setAcademics,
            setCertifications,
            setLanguages,
            setLanguagesCache,
            setLanguageErrors,
            setSkills,
            setSkillErrors,
            setSkillsCache
        }}>
            { children }
        </CandidateContext.Provider>
    )

}