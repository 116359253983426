import {
    useContext,
    useEffect,
    useState
} from 'react';
import { AppContext } from '../../../../../router/approuter';
import { CandidateContext } from '../../context';
import styles from '../../candidateInfo.module.scss';
import addIcon from '../../../../../assets/icons/add_circle.svg';
import trashIcon from '../../../../../assets/icons/trash.svg';
import { getSkills } from '../../../../api/skillClient';
import { objEquals, setSkillSelecValue } from '../../../../core/helper';
import { getOptionsSelecteds, validateSkillRequest } from './skills';
import { getSkillsCatalog } from '../../../../api/catalogClient';
import { InputSearcher } from './InputSearcher';
import { InputPercentage } from './InputPercentage';

const Software = ({
    resumeViewFlag,
    renderBackButton
}) => {

    const appContext = useContext(AppContext)

    const {
        skill,
        cleanSkill,
        setSkills,
        skillErrors,
        setSkillErrors,
        skillsCache,
        setSkillsCache
    } = useContext( CandidateContext );

    const [catSkills, setCatSkills] = useState([]);

    const loadCatSkills = () => {
        appContext.showLoading(true, 'Cargando', styles.no_scroll);
        getSkillsCatalog(0, 10000, appContext.userData.token).then((response) => {
            let catTmp = [];
            if( response.status === 200 && response.data && response.data.result && response.data.result.content && Array.isArray(response.data.result.content) ) {
                response.data.result.content.map( r => {
                    catTmp.push({ id: r.id ,optionName: r.name, categoryId: r.categoryId, percentage: r.skillLevelPercentage});
                })
                setCatSkills(catTmp);
            }
            loadSkills(catTmp);
            appContext.showLoading(false, '', styles.no_scroll);
        }).catch(error => {
            appContext.showLoading(false, '', styles.no_scroll);
            console.log(error)
        })
    }

    const loadSkills = catSkills => {
        getSkills(appContext.userData.userProfile.talentId, appContext.userData.token).then((response) => {
            if( response.data && response.data.code === 201 && response.data.result ) {
                let skillsTmp = [];
                response.data.result.map( (skill, index) => {
                    const skillWithTagValue = setSkillSelecValue(skill, index, catSkills);
                    skillsTmp.push(skillWithTagValue);
                })
                skillsTmp = skillsTmp.sort( (a, b) => sorterAlphAsc(a,b) );
                setSkills(skillsTmp);
                setSkillsCache(skillsTmp);
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const sorterAlphAsc = (a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if( nameA < nameB ) {
            return -1;
        }
        if( nameA > nameB ) {
            return 1;
        }
        return 0;
    }

    const onClickAdd = categoryId => {
        const validationHelper = validateSkillRequest(categoryId, skillsCache);
        if (Object.keys(validationHelper).length === 0) {
            const skillNew = { ...skill, 'categoryId': categoryId };
            cleanSkill(categoryId);
            setSkillsCache([ ...skillsCache, skillNew]);
        }
        setSkillErrors(validationHelper);
    }

    const handleOnClickRemove = skill => {
        let skillsCacheTmp = [ ...skillsCache ];
        skillsCacheTmp = skillsCache.filter( s => !objEquals(s.tag, skill.tag));
        setSkillsCache(skillsCacheTmp);
    }

    const updateSkill = (index, tagId, categoryId, value, isSelected) => {
        let skillsCacheTmp = [ ...skillsCache ];
        skillsCacheTmp.filter( s => s.categoryId === categoryId )[index].tag = value;
        skillsCacheTmp.filter( s => s.categoryId === categoryId )[index].tagId = isSelected ? value.id : tagId;
        skillsCacheTmp.filter( s => s.categoryId === categoryId )[index].id = value.id;
        skillsCacheTmp.filter( s => s.categoryId === categoryId )[index].name = value.description;
        setSkillsCache( skillsCacheTmp );
    }

    const updatePercentage = (value, index, categoryId) => {
        let skillsCacheTmp = [ ...skillsCache ];
        skillsCacheTmp.filter( s => s.categoryId === categoryId )[index].skillLevelPercentage = value;
        setSkillsCache(skillsCacheTmp);
    }

    useEffect(() => {
        setSkillErrors({})
        loadCatSkills();
    }, [])
    

    const renderDeleter = (skill, index) => (
        <div style={{ display: 'flex', gap: '8px' }}>
            <span className={ styles.vl }></span>
            <img className={ styles.trash } src={ trashIcon } alt="" onClick={ () => handleOnClickRemove(skill, index) } />
        </div>
    )

    const renderSkills = (categoryId, skills) => skills.length > 0 && skills.map((skill, index) => (
        skill.action !== 2 && (
            <div key={ `${categoryId}_${index}` }>
                <div className={ `${styles.form_row} ${styles.padded_short}` }>
                    <div className={ styles.flex_14gap }>
                            <InputSearcher
                                id = {`${categoryId}_${index}`}
                                tagId = { skill.tagId }
                                value = { skill.tag }
                                placeholder = { 'Buscar software' }
                                onChange = { updateSkill }
                                options = { catSkills.filter( skill => skill.categoryId === categoryId ) }
                                optionsSelecteds = { getOptionsSelecteds(skills) }
                                error = { Object.keys(skillErrors).includes(`${categoryId}_${index}`) && skillErrors[`${categoryId}_${index}`][0] ? skillErrors[`${categoryId}_${index}`][0] : undefined }
                                index={ index }
                                categoryId = { categoryId }
                                width={ appContext.isDesktopOrLaptop ? 40 : 60 }
                            />
                        <div className={ styles.flex_14gap }>
                            <InputPercentage
                                id = {`per_${categoryId}_${index}`}
                                value = { skill.skillLevelPercentage }
                                onChange = { updatePercentage }
                                error = { Object.keys(skillErrors).includes(`${categoryId}_${index}`) && skillErrors[`${categoryId}_${index}`][1] ? skillErrors[`${categoryId}_${index}`][1] : undefined }
                                index={ index }
                                categoryId = { categoryId }
                            />
                            { renderDeleter(skill, index) }
                        </div>
                    </div>
                </div>
                <div className={ styles.form_row }>
                    <div className={ styles.flex_14gap }>
                        {Object.keys(skillErrors).includes(`${categoryId}_${index}`) && skillErrors[`${categoryId}_${index}`][0] ? (
                            <p className={ styles.error_message } style={{ width: '40%' }}>{ skillErrors[`${categoryId}_${index}`][0] }</p>
                        ) : Object.keys(skillErrors).includes(`${categoryId}_${index}`) && skillErrors[`${categoryId}_${index}`][1] && (
                            <p style={{ width: '40%' }}></p>
                        )}
                        {Object.keys(skillErrors).includes(`${categoryId}_${index}`) && skillErrors[`${categoryId}_${index}`][1] && (
                            <p className={ styles.error_message }>{ skillErrors[`${categoryId}_${index}`][1] }</p>
                        )}
                    </div>
                </div>
            </div>
        )
    ));

    const renderComponentSkill = (categoryId, title, descriptions, buttonLabel) => {
        const skills = skillsCache.filter( skill => skill.categoryId === categoryId );
        return (
            <div className={ styles.form_wrapper }>
                <p className={ styles.form_section_title_2 }>{ title }</p>
                <div className={ `${styles.form_row} ${styles.no_gap} ${ title ? styles.top_padded : ''}` }>
                    { descriptions && descriptions.map((d, index) => (<p key={index} className={ styles.subtitle }>{ d }</p>)) }
                </div>
                {
                    skills.length > 0 &&
                        <div className={ `${styles.form_row} ${styles.no_gap} ${styles.top_padded}` }>
                            <div className={ styles.flex_14gap }>
                                <label style={{ width: '40%' }}>Software*</label>
                                <label>Dominio*</label>
                            </div>
                        </div>
                }
                { renderSkills(categoryId, skills) }
                <div className={ `${styles.form_row} ${styles.top_padded}` }>
                    <button className={ styles.add_info } onClick={ () => onClickAdd(categoryId) }>
                        { buttonLabel }
                        <img src={ addIcon } alt="" />
                    </button>
                </div>
            </div>
        )
    }

    const title = 'Software';
    const renderTitle = () => (<p className={ styles.title }>{ title }</p>);

    return (
        <div className={ styles.form_container }>
            { appContext.isDesktopOrLaptop ? renderTitle() : ( resumeViewFlag ? renderTitle() : renderBackButton(title)) }
            { renderComponentSkill(1001100004, null, [ "Agrega los software que sepas utilizar y su porcentaje de dominio.",  "En caso de no utilizar ningún software, da click en “Omitir”."], 'Agregar software') }
        </div>
    )
}

export default Software;