import { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './input.module.scss';
import { ReactComponent as ArrowdDown } from '../../../assets/icons/arrow_down.svg';
import { ReactComponent as IconClose } from '../../../assets/icons/close_small.svg';
import { borderError, removeAccents } from '../../core/helper';
import { selectEmtpy } from '../../core/hardcode';
import { AppContext } from '../../../router/approuter';

export const SelectSortType = {
	WE_SECTOR: 'we_sector',
	ALPHA: 'alpha',
	LANG: 'lang'
}

const Select = ({
	value,
	name,
	options,
	placeholder,
	onChange,
	disabled,
	error,
	index,
	optionsSelecteds,
	removedFromIcon,
	sercheable,
	sort
}) => {
	const appContext = useContext(AppContext);
	const ref = useRef()
	
	const [filterOptions, setFilterOptions] = useState([]);
	const [showOptions, setShowOptions] = useState(false);
	const [selected, setSelected] = useState(value.description !== '');

	const setOptions = opts => {
		let optTmp = opts.sort((a, b) => a.id - b.id);
		if( sort && sort === SelectSortType.WE_SECTOR ) {
			const optOther = opts.find( o => o.optionName === 'Otro' );
			optTmp = opts.filter( o => o.optionName !== 'Otro' );
			optTmp.push(optOther);
		} else if( sort && sort === SelectSortType.ALPHA ) {
			optTmp = opts.sort((a, b) => (removeAccents(a.optionName) > removeAccents(b.optionName)) ? 1 : -1);
		} else if ( sort && sort === SelectSortType.LANG ) {
			const optIng = opts.find( o => o.id === 1 );
			optTmp = opts.filter( o => o.id >= 3 );
			optTmp = [ optIng, ...optTmp.sort((a, b) => (removeAccents(a.optionName) > removeAccents(b.optionName)) ? 1 : -1) ];
		}
		if ( optionsSelecteds && optionsSelecteds.length > 0 ) {
			optTmp = optTmp.filter( o => !optionsSelecteds.includes(o.id) );
		}
		setFilterOptions(optTmp)
	}

	const setFiltredOptions = value => {
		const optTmp = options.filter( ({ optionName }) => optionName.toLowerCase().includes(value.toLowerCase()) );
		if ( optTmp && optTmp.length > 0 ) {
			setOptions(optTmp);
			setShowOptions(true);
		} else {
			setShowOptions(false);
		}
	}

	useEffect(() => {
		setOptions(options);
	}, [options, optionsSelecteds])

	const setValue = idToFind => {
		if( idToFind ) {
			const optionSelected = options.filter(({ id }) => (id === idToFind));
			if( optionSelected && optionSelected.length > 0 ) {
				const newValue = optionSelected 
				? { id: optionSelected[0].id, description: optionSelected[0].optionName }
				: selectEmtpy;
				if ( index ) {
					onChange(index, name, newValue, value);
				} else {
					onChange(name, newValue);
				}
				setSelected(true);
			} /*else {
				onChange(name, selectEmtpy);
			}*/
		} else {
			if ( index ) {
				onChange(index, name, selectEmtpy, value);
			} else {
				onChange(name, selectEmtpy);
			}
			setSelected(false);
		}
	}

	const onSelectOption = (id) => {
		setValue(id);
		handleSelectOnClick(false)
	}

	const onCleanOption = () => {
		if( !disabled ) {
			if (removedFromIcon) {
				removedFromIcon(value.id)
			}
			handleSelectOnClick(false);
			setValue(0);
			setSelected(false);
		}
	};

	const onClickOutside = () => {
		setShowOptions(false);
	}

	useEffect(() => {
		const handleClickOutside = (event) => {
		  if (ref.current && !ref.current.contains(event.target)) {
			onClickOutside && onClickOutside();
		  }
		};
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	  }, [ onClickOutside ]);

	const renderOptions = () => (
		showOptions && filterOptions.length > 0 && (
			<div className={ styles.select_container } ref={ ref } style={ sort === SelectSortType.WE_SECTOR ? { height: '160px' } : { } } >
				<ul>
					{
						filterOptions.map(({ id, optionName }) => (
							<li
								value={ id }
								key={ id }
								onMouseDown={ () => onSelectOption(id) }
							>
								{ optionName }
							</li>
						))
					}
				</ul>
			</div>
		)
	);

	const handleOnBlur = () => setTimeout(() => handleSelectOnClick(false), 100);

	const cursorPointer = !disabled ? { cursor: 'pointer' } : {};

	const renderIcon = () => {
		return selected
			? <IconClose className={ styles.select_icon_close } style={ cursorPointer } onClick={ onCleanOption }  onBlur={ handleOnBlur } />
			: <ArrowdDown className={ styles.select_icon_arrow } style={ cursorPointer } onClick = { () => handleSelectOnClick(true) }  onBlur={ handleOnBlur } />
	};

	const handleSelectOnClick = showOptions => {
		if (!disabled) {
			setShowOptions(showOptions)
		}
	}

	const onType = ({ target }) => {
        if ( index ) {
			onChange(index, name, { description: target.value });
		} else {
			onChange(name, { description: target.value });
		}

		if( target.value === '' ) {
			setShowOptions(true);
			setOptions(options);
		} else {
			setFiltredOptions(target.value);
		}
	};

	const getStyle = () => ({
		...borderError(error), 
		paddingLeft: '16px', 
		paddingRight: appContext.isDesktopOrLaptop ? '48px' : '32px'
	})

	return (
		<div className={ styles.input }>
			<div className={ styles.input_container }>
				<input className={ disabled ? styles.input_select_blocked : styles.input_select }
					style={ getStyle() }
					readOnly={ !sercheable || selected }
					autoComplete='off'
					name={ `${name}Value` }
					placeholder={ placeholder }
					value={ value.description }
					type="text"
					disabled={ disabled }
					onChange={ onType }
					onClick={ () => handleSelectOnClick(true) }
				/>
				{ renderIcon() }
				{ renderOptions() }
			</div>
		</div>
	);
};

export default Select;

export const selectPropType = {
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	value: PropTypes.shape({
        id: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
    }).isRequired,
	onChange: PropTypes.func.isRequired,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([
				PropTypes.number,
				PropTypes.string,
			]),
			optionName: PropTypes.string.isRequired,
		}),
	).isRequired,
	diabled: PropTypes.bool,
	error: PropTypes.string,
	index: PropTypes.number,
	optionsSelecteds: PropTypes.array,
	removedFromIcon: PropTypes.func,
	sercheable: PropTypes.bool,
	sort: PropTypes.string
};

Select.propTypes = selectPropType;

Select.defaultProps = {
	value: selectEmtpy,
	disabled: false,
	index: 0,
	optionsSelecteds: [],
	sercheable: false
};