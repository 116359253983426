import {
    useContext,
    useEffect,
    useState
} from 'react';
import styles from '../../candidateInfo.module.scss';
import addIcon from '../../../../../assets/icons/add_circle.svg';
import trashIcon from '../../../../../assets/icons/trash.svg';
import infoIcon from '../../../../../assets/icons/info_icon.svg';
import Select, { SelectSortType } from '../../../../components/inputs/Select';
import { CandidateContext } from '../../context';
import { setLanguageSelecValues } from '../../../../core/helper';
import { getLanguages } from '../../../../api/languageClient';
import { validateIsSelected } from '../../../../core/validators';
import { AppContext } from '../../../../../router/approuter';
import { getLangLevelCatalog, getLanguageCatalog } from '../../../../api/catalogClient';

export const validateLanguageRequest = languages => {
    const errorObject = {};
    if( languages.length > 0 ) {
        languages.forEach( (l, index) => {
            const langValidation = validateIsSelected(l.language);
            if( langValidation ) {
                errorObject[`language_${index}`] = langValidation
            }
            const levelValidation = validateIsSelected(l.langLevel);
            if( levelValidation ) {
                errorObject[`langLevel_${index}`] = levelValidation
            }
        })
    }
    return errorObject;
}

const Languages = ({
    resumeViewFlag,
    renderBackButton,
    setStepIndex,
    displayNotification
}) => {
    const appContext = useContext(AppContext)

    const {
        language,
        cleanLanguage,
        languages, 
        setLanguages,
        languagesCache,
        setLanguagesCache,
        languageErrors,
        setLanguageErrors
    } = useContext( CandidateContext );

    const [classPadded, setClassPadded] = useState(styles.padded);
    const [catLanguages, setCatLanguages] = useState();
    const [catLevels, setCatLevels] = useState();
    const [optionsSelecteds, setOptionsSelecteds] = useState([]);

    const loadCatLanguages = () => {
        appContext.showLoading(true, 'Cargando', styles.no_scroll);
        getLanguageCatalog(appContext.userData.token).then((response) => {
            appContext.showLoading(false, '', styles.no_scroll);
            if( response.status === 200 && response.data && response.data.code === 202 && response.data.result && Array.isArray(response.data.result) ) {
                let languagesTmp = [];
                response.data.result.forEach( l => {
                    if( l.languageName !== 'Español' ) {
                        const language = { id: l.id, optionName: l.name}
                        languagesTmp.push(language);
                    }
                })
                setCatLanguages(languagesTmp);
                loadCatLangLevels(languagesTmp);
            }
        }).catch(error => {
            appContext.showLoading(false, '', styles.no_scroll);
            console.log(error)
        })
        appContext.setFromInfo(false);
    }

    const loadCatLangLevels = (catLanguages) => {
        appContext.showLoading(true, 'Cargando', styles.no_scroll);
        getLangLevelCatalog(appContext.userData.token).then((response) => {
            appContext.showLoading(false, '', styles.no_scroll);
            if( response.status === 200 && response.data && response.data.code === 202 && response.data.result && Array.isArray(response.data.result) ) {
                let langLevelsTmp = [];
                response.data.result.forEach( l => {
                    let desc = l.description ? l.description : '';
                    desc = desc.substring(0, desc.indexOf(':'));
                    desc = `${l.name}${desc ? ' - ' : ''}${desc}`;
                    const language = { id: l.id, optionName: desc}
                    langLevelsTmp.push(language);
                })
                setCatLevels(langLevelsTmp);
                loadLanguages(catLanguages, langLevelsTmp);
            }
        }).catch(error => {
            appContext.showLoading(false, '', styles.no_scroll);
            console.log(error)
        })
    }

    const loadLanguages = (catLanguages, catLevels) => {
        appContext.showLoading(true, 'Cargando', styles.no_scroll);
        getLanguages(appContext.userData.userProfile.talentId, appContext.userData.token).then((response) => {
            appContext.showLoading(false, '', styles.no_scroll);
            if( response.data && response.data.code === 201 && response.data.result ) {
                let languagesTmp = [];
                let languagesSelected = [];
                response.data.result.forEach( (language, index) => {
                    const laWithSelecValues = setLanguageSelecValues(language, index, catLanguages, catLevels);
                    languagesTmp.push(laWithSelecValues);
                    languagesSelected.push(language.languageId);
                })
                setOptionsSelecteds(languagesSelected)
                setLanguages(languagesTmp);
                setLanguagesCache(languagesTmp);
            }
        }).catch(error => {
            appContext.showLoading(false, '', styles.no_scroll);
            console.log(error)
        })
    }

    const onClickAdd = () => {
        if( !catLanguages ) {
            displayNotification('Error al cargar los idiomas, intenta nuevamente', true, 3000, false);
            loadCatLanguages();
        } else {
            const errorValidations = validateLanguageRequest(languages);
            setLanguageErrors(errorValidations);
            const errors = Object.keys(errorValidations);
            const errorLang = errors.filter( w => w.includes('language_')).length > 0;
            const errorLevel = errors.filter( w => w.includes('langLevel_')).length > 0;
            if ( !(errorLang || errorLevel) && languages.length < catLanguages.length ) {
                const index = languages.length;
                cleanLanguage();
                const levelBasic = catLevels.find( c => c.id === 1 );
                const tmpLanguage = {
                    ...language,
                    'index': index,
                    'langLevel': { id: levelBasic.id, description: levelBasic.optionName }
                }
                setLanguages([...languages, tmpLanguage]);
                setClassPadded( styles.padded_short );
            }
        }
    }

    const handleOnClickRemove = index => {
        // eliminando el idioma de los idiomas seleccionados
        setOptionsSelecteds([...optionsSelecteds.slice(0, index), ...optionsSelecteds.slice(index + 1)])
        const languagesTmp = [ ...languages ];
        languagesTmp.splice(index, 1);
        setLanguages( languagesTmp );
        setLanguageErrors(validateLanguageRequest(languagesTmp));
    }

    const updateLanguage = (index, property, value, preValue) => {
        const indexReal = index - 1;
        const propertyReal = property.replace(`_${indexReal}`, '');
        const language = {
            ...languages.find( l => l.index === indexReal ),
            [propertyReal]: value && value.id ? value : { id: 0, description: value.description },
            [`${propertyReal}Id`]: value.id ? value.id : 0
        }
        // Fix when update language without remove it
        if( propertyReal === 'language' && value.id !== preValue.id ) {
            const langInCache = languagesCache.find(l => l.languageId === value.id);
            if(langInCache) { // If revert change, id is retrieve from cache
                language.id = langInCache.id;
            } else { // else id is removed
                delete language.id;
            }
        }

        // revisando los lenguajes ya seleccionados
        if (propertyReal === 'language' && value && value.id && value.id !== 0) {
            const optSelectedTmp = optionsSelecteds.filter( o => o !== preValue.id );
	        setOptionsSelecteds([...optSelectedTmp, value.id])
        }
        let languagesTmp = [ ...languages ];
        languagesTmp[indexReal] = language;
        setLanguages(languagesTmp);
        setLanguageErrors(validateLanguageRequest(languagesTmp));
    }

    const removedFromIcon = (id) => {
        const indexHelper = optionsSelecteds.indexOf(id)
        setOptionsSelecteds([...optionsSelecteds.slice(0, indexHelper), ...optionsSelecteds.slice(indexHelper + 1)])
    }

    useEffect(() => {
        if ( !appContext.fromInfo ) {
            loadCatLanguages();
        }
    }, []);

    const goToInfo = () => {
        appContext.setFromInfo(true);
        setStepIndex(7);
    }

    const renderTitles = index => languages && languages.length > 0 && (
        <div className={ `${styles.form_columns_line} ${ index === 0 ? styles.top_padded : styles.top_padded_short }` }>
            <div className={ styles.form_column }>
                <label>{ `Idioma ${index+1}*`}</label>
            </div>
            <div className={ styles.form_columns_line_2 }>
                <label>{ index === 0 ? 'Nivel*' : ''}</label>
                { index === 0 && (<img src={ infoIcon } alt="" width="24" height="24" onClick={ () => goToInfo() } />) }
            </div>
        </div>
    )

    const renderLanguages = () => (
        languages && languages.length > 0 && languages.map((la, index) => {
            return (
                <div key={index}>
                    { renderTitles( index) }
                    <div className={ styles.form_columns_line }>
                        <div className={ styles.form_column }>
                            <Select
                                name={`language_${index}`}
                                placeholder="Selecciona un idioma"
                                value={ la.language }
                                sercheable
                                onChange={ updateLanguage } 
                                options={ catLanguages ? catLanguages : [] }
                                optionsSelecteds={ optionsSelecteds }
                                removedFromIcon={ removedFromIcon }
                                index={ index+1 }
                                error={ Object.keys(languageErrors).includes(`language_${index}`) ? languageErrors[`language_${index}`][0] : '' }
                                sort={ SelectSortType.LANG }
                            />
                            {
                                Object.keys(languageErrors).includes(`language_${index}`) && (
                                    <p className={ styles.error_message_font }>{ `${languageErrors[`language_${index}`][0]}*` }</p>
                                )
                            }
                        </div>
                        <div className={ styles.form_column }>
                            <div className={ styles.form_column_line }>
                                <Select 
                                    name={`langLevel_${index}`}
                                    placeholder="Selecciona un nivel"
                                    value={ la.langLevel }
                                    onChange={ updateLanguage } 
                                    options={ catLevels ? catLevels : [] }
                                    index={ index+1 }
                                    error= { Object.keys(languageErrors).includes(`langLevel_${index}`) ? languageErrors[`langLevel_${index}`][0] : '' }
                                />
                                <span className={ styles.vl }></span>
                                <button className={ styles.add_info } onClick={ () => handleOnClickRemove(index) }>
                                    <img src={ trashIcon } alt="" />
                                </button>
                            </div>
                            {
                                Object.keys(languageErrors).includes(`langLevel_${index}`) && (
                                    <p className={ styles.error_message_font }>{ `${languageErrors[`langLevel_${index}`][0]}*` }</p>
                                )
                            }
                        </div>
                    </div>
                    
                </div>
            )}
        )
    );

    const title = 'Idiomas';
    const renderTitle = () => (<p className={ styles.title }>{ title }</p>);

    return (
        <div className={ styles.form_container }>
            { appContext.isDesktopOrLaptop ? renderTitle() : ( resumeViewFlag ? renderTitle() : renderBackButton(title)) }
            <div className={ styles.form_wrapper }>
                <div className={ styles.form_row + " " + styles.no_gap }>
                    <p className={ styles.subtitle }>
                        Agrega los idiomas que domines actualmente, adicional al Español.
                    </p>
                    <p className={ styles.subtitle }>
                        En caso de no dominar algún otro, da click en “Omitir”.
                    </p>
                    { /* renderTitles() */ }
                    { renderLanguages() }
                </div>
                <div className={ styles.form_row + " " + classPadded }>
                    <button className={ styles.add_info } onClick={ () => onClickAdd() }>
                        Agregar idioma
                        <img src={ addIcon } alt="" />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Languages;