import { types } from "./types";

export const candidateReducer = ( state = {}, action ) => {
    switch ( action.type ) {
        case types.setPersonalInfo:
            return {
                ...state,
                personalInfoCache: action.payload
            };
        case types.setWorkExperience:
            return {
                ...state,
                workExperience: action.payload
            };
        case types.setAcademic:
            return {
                ...state,
                academic: action.payload
            };
        case types.setCertification:
            return {
                ...state,
                certification: action.payload
            };
        case types.setLanguage:
            return {
                ...state,
                language: action.payload
            };
        case types.setSkill:
            return {
                ...state,
                skill: action.payload
            };
        case types.setWorkExperiences:
            return {
                ...state,
                workExperiences: action.payload
            };
        case types.setAcademics:
            return {
                ...state,
                academics: action.payload
            };
        case types.setCertifications:
            return {
                ...state,
                certifications: action.payload
            };
        case types.setLanguages:
            return {
                ...state,
                languages: action.payload
            };
        case types.setLanguagesCache:
            return {
                ...state,
                languagesCache: action.payload
            };
        case types.setLanguageErrors:
            return {
                ...state,
                languageErrors: action.payload
            };
        case types.setSkills:
            return {
                ...state,
                skills: action.payload
            };
        case types.setSkillErrors:
            return {
                ...state,
                skillErrors: action.payload
            };
        case types.setSkillsCache:
            return {
                ...state,
                skillsCache: action.payload
            }
        default:
            return state;
    }
}