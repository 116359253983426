import PropTypes from 'prop-types';
import styles from './modal_base.module.scss'

export const ModalDelete = ({
    title,
    subtitle,
    onClick,
    onClickCancel
}) => {
    return (
        <div className={ styles.modal_delete }>
            <p className={ styles.title } >{ `Borrar “${title}”` }</p>
            <p className={ styles.subtitle }>{ subtitle }</p>
            <div className={ styles.buttons }>
                <button className={ styles.cancel_button } onClick={ onClickCancel }>Cancelar</button>
                <button onClick={ onClick }>Borrar</button>
            </div>
        </div>
    )
}

ModalDelete.propTypes = {
    title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired
};