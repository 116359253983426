import { useContext, useState } from 'react';

import styles from './notification.module.scss';
import checkIcon from '../../../assets/icons/checkicon_white.svg';
import wifiErrorIcon from '../../../assets/icons/wifi_error.svg';
import { AppContext } from '../../../router/approuter';

const Notification = (props) => {

    const appContext = useContext(AppContext)

    return(
        <div className={ appContext.isDesktopOrLaptop ? styles.notification : styles.notification_mobile } 
            style={ { display: props.data.isDisplay ? 'block' : 'none' } }>
            <div className={ props.data.notificationStyle }>
                <img src={ props.data.isErrorConection ? wifiErrorIcon : (props.data.icon ? props.data.icon : checkIcon) } alt="" />
                <p>{ props.data.message ? props.data.message : '¡Conexión débil o inexistente! Verifica tu conexión y vuelve a intentarlo.' }</p>
            </div>
        </div>
    )
}
export default Notification