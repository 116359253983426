import { 
    useEffect,
    useState } from 'react';

import styles from '../signup.module.scss';
import stylesCandidate from '../../candidates/candidateInfo.module.scss';
import signUpImage from '../../../../assets/img/create-profile.png';

import {ReactComponent as HidePassIcon} from '../../../../assets/icons/hide_password.svg';
import {ReactComponent as ShowPassIcon} from '../../../../assets/icons/view_password.svg';
import {ReactComponent as ValidationIcon} from '../../../../assets/icons/check_gray.svg';

import { 
    validateIsAlpha,
    validateEmail,
    validateNotNull,
    validateIsChecked,
    validateMinLength,
    validateLowerCharacter,
    validateUpperCase,
    validateNumber
 } from '../../../core/validators';
import { signUp, validateEmailUsed } from '../../../api/userClient';

const SignUpForm = (props) => {

    const careersUrl = process.env.REACT_APP_CAREERS_LANDING;

    const { signUpInfo, setSignUpInfo, setStepIndex, appContext } = props;

    const [passwordVisibilityFlag, setPasswordVisibilityFlag] = useState(true)
    const [formErrors, setFormErrors] = useState(false)
    const [requestFlag, setRequestFlag] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(false)

    useEffect(() => {
        const formDataFromLS = JSON.parse(localStorage.getItem('signUpInfo'));
        if( Object.keys(signUpInfo).length === 0 && formDataFromLS ) {
            setTimeout( () => {
                setSignUpInfo( formDataFromLS );
                localStorage.removeItem('signUpInfo');
            }, 1000)
        } else if (Object.keys(signUpInfo).length > 0 && !formDataFromLS) {
            setSignUpInfo({});
        }
    }, [])
    

    const validationFields = {
        name: [validateIsAlpha, validateNotNull],
        lastName: [validateIsAlpha, validateNotNull],
        email: [validateEmail, validateNotNull],
        terms: [validateIsChecked],
        password: [validateLowerCharacter, validateUpperCase, validateNumber, validateMinLength, validateNotNull]
    }

    /*const onClickVerificyCode = () => {
        appContext.setModalFlag(false)
        setStepIndex(1);
    }*/

    const handleSubmit = event => {
        appContext.displayNotification(null, true, 'hide');
        event.preventDefault();
        setRequestFlag(true);
        const validationErrors = validateRequest();
        if (Object.keys(validationErrors).length === 0) {
            appContext.showLoading(true, 'Cargando', stylesCandidate.no_scroll);
            setShowSuggestions(false);
            validateEmailUsed(signUpInfo.email).then(({status, data}) => {
                if (status === 202 && data && data.code === 404) {
                    const request = {
                        "email": signUpInfo.email,
                        "password": signUpInfo.password,
                        "firstName": signUpInfo.name,
                        "lastName": signUpInfo.lastName,
                        "typeUser": 1000800007,
                        "passwordConfirm": signUpInfo.password,
                        "source": "Careers",
                        "roleId": [1000000001],
                        "clientId": 1000000002,
                        "statusId": 1000800004
                    };
                    signUp(request).then(({status}) => {
                        appContext.showLoading(false, '', stylesCandidate.no_scroll);
                        appContext.displayNotification("Código de verificación enviado");
                        setStepIndex(1);
                    }).catch( error => {
                        appContext.showLoading(false, '', stylesCandidate.no_scroll);
                        if( error.code === 'ERR_NETWORK' ) {
                            appContext.displayNotification(null, true);
                        }
                        console.log(error);
                    })
                } else {
                    // appContext.setModalContent( <VerificationModal onClickVerificyCode={ onClickVerificyCode } /> )
                    // appContext.setModalFlag(true)
                    // setSignUpInfo(signUpInfo);
                    setFormErrors({
                        email: ["Dirección de correo ya registrada. Si esta dirección es tuya, inicia sesión para continuar."]
                    })
                    appContext.showLoading(false, '', stylesCandidate.no_scroll);
                }
            }).catch( error => {
                appContext.showLoading(false, '', stylesCandidate.no_scroll);
                console.log('Error', error);
                if( error.code === 'ERR_NETWORK' ) {
                    appContext.displayNotification(null, true);
                }
            })
        } else {
            setFormErrors(validationErrors)
        }
    }

    const handleFormChange = event => {
        const dataForm = { ...signUpInfo, [event.target.name]: event.target.name === "terms" ? event.target.checked: event.target.value}
        setSignUpInfo(dataForm);
        localStorage.setItem('signUpInfo', JSON.stringify(dataForm));
    }

    const validateRequest = () => {
        const errorObject = {};
        var helper;
        // validating fields
        for(const field in validationFields) {
            for (const validator in validationFields[field]) {
                helper = validationFields[field][validator](signUpInfo[field])
                if (helper) {
                    errorObject[field] = helper
                }
            }
        }
        return errorObject
    }

    const renderSuggestions = () => appContext.isDesktopOrLaptop && showSuggestions && (
        <div className={ styles.password_validator +  " " + ((signUpInfo.password && signUpInfo.password.length > 0) ? styles.active : undefined) }>
            <div className={ styles.validator_row }>
                <p className={ styles.validator_title }>Tu contraseña debe tener:</p>
            </div>
            <div className={ styles.validator_row  +  " " + (!validateMinLength(signUpInfo.password) ? styles.validated : (((validateMinLength(signUpInfo.password) && requestFlag ) ?  styles.error : undefined )) )  }>
                <ValidationIcon />
                <p>Al menos 8 caractéres</p>
            </div> 
            <div className={ styles.validator_row +  " " + (!validateLowerCharacter(signUpInfo.password) ? styles.validated : (((validateLowerCharacter(signUpInfo.password) && requestFlag ) ?  styles.error : undefined )) ) }>
                <ValidationIcon />
                <p>Al menos una letra minúscula</p>
            </div>
            <div className={ styles.validator_row +  " " + (!validateUpperCase(signUpInfo.password) ? styles.validated : (((validateUpperCase(signUpInfo.password) && requestFlag ) ?  styles.error : undefined ))) }>
                <ValidationIcon />
                <p>Al menos una letra mayúscula</p>
            </div>
            <div className={ styles.validator_row +  " " + (!validateNumber(signUpInfo.password) ? styles.validated : (((validateNumber(signUpInfo.password) && requestFlag ) ?  styles.error : undefined ))) }>
                <ValidationIcon />
                <p>Al menos un número</p>
            </div>
            <div className={ styles.box_notch }></div>
        </div>
    )

    const renderSuggestionsMobile = () => {
        return !appContext.isDesktopOrLaptop && (
            <div className={ `${styles.password_validator} ${styles.active}` }>
                <div className={ styles.validator_row }>
                    <p className={ styles.validator_title }>Tu contraseña debe tener:</p>
                </div>
                <div className={ styles.validator_row  +  " " + (!validateMinLength(signUpInfo.password) ? styles.validated : (((validateMinLength(signUpInfo.password) && requestFlag ) ?  styles.error : undefined )) )  }>
                    <ValidationIcon />
                    <p>Al menos 8 caractéres</p>
                </div> 
                <div className={ styles.validator_row +  " " + (!validateLowerCharacter(signUpInfo.password) ? styles.validated : (((validateLowerCharacter(signUpInfo.password) && requestFlag ) ?  styles.error : undefined )) ) }>
                    <ValidationIcon />
                    <p>Al menos una letra minúscula</p>
                </div>
                <div className={ styles.validator_row +  " " + (!validateUpperCase(signUpInfo.password) ? styles.validated : (((validateUpperCase(signUpInfo.password) && requestFlag ) ?  styles.error : undefined ))) }>
                    <ValidationIcon />
                    <p>Al menos una letra mayúscula</p>
                </div>
                <div className={ styles.validator_row +  " " + (!validateNumber(signUpInfo.password) ? styles.validated : (((validateNumber(signUpInfo.password) && requestFlag ) ?  styles.error : undefined ))) }>
                    <ValidationIcon />
                    <p>Al menos un número</p>
                </div>
                <div className={ styles.box_notch }></div>
            </div>
        )
    }

    return (
        <div className={ styles.form_wrapper }>
            <div className={ styles.form_column }>
                <form className={ styles.form } onChange={ (event) => handleFormChange(event) } onSubmit={(event) => handleSubmit(event) }>
                    <div className={ styles.inputs}>
                        <div className={ styles.input_field }>
                            <label htmlFor="">Nombres*</label>
                            <div>
                                <input className={ Object.keys(formErrors).includes("name") ? styles.error : undefined } name="name" type="text" onClick={ () => setShowSuggestions(false) } />
                                {
                                    Object.keys(formErrors).includes("name") && (
                                        <p className={ styles.error_message }>{ formErrors.name[0] }</p>
                                    )
                                }
                            </div>
                        </div>
                        <div className={ styles.input_field }>
                            <label htmlFor="">Apellidos*</label>
                            <div>
                                <input className={ Object.keys(formErrors).includes("lastName") ? styles.error : undefined } name="lastName" type="text" onClick={ () => setShowSuggestions(false) } />
                                {
                                    Object.keys(formErrors).includes("lastName") && (
                                        <p className={ styles.error_message }>{ formErrors.lastName[0] }</p>
                                    )
                                }
                            </div>
                        </div>
                        <div className={ styles.input_field }>
                            <label>Correo electrónico*</label>
                            <div>
                                <input className={ Object.keys(formErrors).includes("email") ? styles.error : undefined } name="email" type="text" onClick={ () => setShowSuggestions(false) } />
                                {
                                    Object.keys(formErrors).includes("email") && (
                                        <p className={ styles.error_message }>{ formErrors.email[0] }</p>
                                    )
                                }
                            </div>
                        </div>
                        <div className={ styles.input_field }>
                            <label htmlFor="">Contraseña*</label>
                            <div className={ styles.password_container + " " + (Object.keys(formErrors).includes("password") ? styles.error : undefined)}>
                                <input type={ passwordVisibilityFlag ? "password" : "text" } name="password" onClick={ () => setShowSuggestions(true) } />
                                <button type='button' onClick={(() => setPasswordVisibilityFlag(!passwordVisibilityFlag))}>
                                    { passwordVisibilityFlag ? <HidePassIcon /> : <ShowPassIcon /> } 
                                </button>
                            </div>
                            {
                                Object.keys(formErrors).includes("password") && (
                                    <p className={ styles.error_message }>{ formErrors.password[0] }</p>
                                )
                            }
                        </div>
                    </div>
                    { renderSuggestionsMobile() }
                    <div className={ styles.buttons }>
                        <div className={ styles.privacy_terms}>
                            <label className={ styles.lbl_checkbox }>
                                <input name="terms" type="checkbox" onClick={ () => setShowSuggestions(false) } />
                            </label>
                            <div>
                                <p>He leido y acepto los términos de la <span><a href={ `${careersUrl}privacy` }>Política de Privacidad.</a></span></p>
                                {
                                    Object.keys(formErrors).includes("terms") && (
                                        <p className={ styles.error_message }>Por favor, lee y acepta los términos y condiciones</p>
                                    )
                                }
                            </div>
                        </div>
                        <button type="submit">Crear cuenta</button>
                        <hr />
                        <div className={ styles.login_links}>
                            <p>¿Ya tienes cuenta?</p>
                            <a href="/login">Inicia sesión aquí</a>
                        </div>
                    </div>
                </form>
                { renderSuggestions() }
            </div>
            <div className={ styles.image_signup }>
                <div className={ styles.text_wrapper }>
                    <div className={ styles.main_text }>
                        <p>Crea tu perfil y post&uacute;late a las grandes oportunidades laborales que tenemos para ti.</p>
                    </div>
                </div>
                { appContext.isDesktopOrLaptop && (<img src={ signUpImage } alt="" />) }
            </div>
        </div>
    )
}

export default SignUpForm;
