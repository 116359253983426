import { useContext } from 'react'
import styles from '../../../components/modal/modal_base.module.scss'
import { AppContext } from '../../../../router/approuter';

export const VerificationModal = ({
    onClickVerificyCode
}) => {

    const appContext = useContext(AppContext);

    return (
        <div className={ styles.modal_verification_code  }>
            <p className={ styles.title } >Anteriormente iniciaste un proceso de registro con esta direcci&oacute;n de correo electr&oacute;nico, pero a&uacute;n no ha sido verificada</p>
            <p className={ styles.subtitle }>Para continuar con este registro, presiona el bot&oacute;n “Verificar direcci&oacute;n de correo”.</p>
            <div className={ styles.buttons }>
                <button className={ styles.cancel_button + " " + styles.mobile } onClick={() => appContext.setModalFlag(false)}>volver</button>
                <button onClick={ onClickVerificyCode }>Verificar direcci&oacute;n de correo  </button>
            </div>
        </div>
    )

}
