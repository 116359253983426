import PropTypes from 'prop-types';
import styles from './input.module.scss';
import { borderError } from '../../core/helper';

export const InputText = ({
    cssContainer,
    name,
	label,
    maxLength,
    placeholder,
    value,
	onChange,
    error,
}) => {

    const handleOnChange = ({ target }) => {
        onChange(name, target.value);
    }

    return (
        <div className={ cssContainer } >
            <label>{ label }</label>
            <div className={ styles.error_container }>
                <input 
                    style={ borderError(error) }
                    autoComplete='off'
                    type="text"
                    name={ name } 
                    placeholder={ placeholder }
                    maxLength={ maxLength }
                    value={ value } onChange={ handleOnChange }
                />
                { error && (<p className={ styles.error_message_font }>{ error }</p>) }
            </div>
        </div>
    )
}

InputText.propTypes = {
    name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string
};

InputText.defaultProps = {
    placeholder: '',
    maxLength: 100,
};