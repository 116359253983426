import { useContext } from 'react';

import styles from './modal_base.module.scss';

import { AppContext } from '../../../router/approuter';

const ModalBase = () => {
    const appContext = useContext(AppContext)

    const getStopClass = () => {
        const isModalCandidateInfo = appContext.modalContent.type.name === 'WorkExperienceModal'
        || appContext.modalContent.type.name === 'AcademicInfoModal'
        || appContext.modalContent.type.name === 'CertificationModal';
        return isModalCandidateInfo && appContext.isDesktopOrLaptop ? styles.stop_propagation_scroll : styles.stop_propagation;
    }

    return (
        // <div className={ styles.modal_base } onClick={() => appContext.setModalFlag(false)}>
        <div className={ `${styles.modal_base} ${ appContext.modalFull ? styles.full : '' }` } onClick={() => appContext.setModalFlag(!appContext.modalFlag)}>
            <div className={ getStopClass() } onClick={(event) => event.stopPropagation() }>
                {
                    appContext.modalContent
                }
            </div>
        </div>
    )
}
export default ModalBase