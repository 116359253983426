import { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './input.module.scss';
import { borderError } from '../../core/helper';

export const TextArea = ({
    cssSubtitle,
    cssCounter,
    name,
	label,
    sublabel,
    maxLength,
    placeholder,
    value,
	onChange,
    error
}) => {

    const [counter, setCounter] = useState(`${value.length}/${maxLength}`);

    const handleOnChange = ({ target }) => {
        setCounter(`${target.value.length}/${maxLength}`);
        onChange(name, target.value);
    }
    return (
        <>
            <label htmlFor="">{ label }</label>
            <label className={ cssSubtitle }> { sublabel }</label>
            <div className={ styles.error_container }>
                <textarea 
                    style={ borderError(value.length >= maxLength || (error && value.length === 0)) }
                    name={ name }
                    placeholder={ placeholder }
                    value={ value }
                    cols="30" rows="10" 
                    maxLength={ maxLength }
                    onChange={ handleOnChange }
                >
                </textarea>
                <div className={ styles.error_container_line }>
                    <div>
                        { 
                            value.length === maxLength && 
                            (<p className={ styles.error_message_font }>Has alcanzado el límite de caracteres permitido.</p>) 
                        }
                        { error && value.length === 0 && (<p className={ styles.error_message_font }>{ error }</p>) }
                    </div>
                    <div className={ cssCounter }>
                        <label className={ `${cssCounter} ${ value.length >= maxLength ? styles.error_message : '' }` } htmlFor="">{ counter }</label>
                    </div>
                </div>
            </div>
        </>
    )
}

TextArea.propTypes = {
    name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string
};

TextArea.defaultProps = {
    placeholder: '',
    maxLength: 100,
};