import { 
    useState,
    useEffect,
    useContext } from 'react';

import styles from "./password_recovery.module.scss";
import stylesCandidate from '../candidates/candidateInfo.module.scss';

import Header from "../../components/header/header"
import Footer from "../../components/footer/footer"

import RecoveryForm from "./components/recoveryForm"
import VerificationCode from './components/verificationCode';

import { AppContext } from '../../../router/approuter';


const PasswordRecovery = () => {

    const appContext = useContext(AppContext)
    const [recoveryInfo, setRecoveryInfo] = useState({})
    const [stepIndex, setStepIndex] = useState(0)

    function handleCancelValidation() {
        setRecoveryInfo({});
        setStepIndex(0);
    }

    const pageSteps = {
        0: <RecoveryForm
            recoveryInfo={ recoveryInfo }
            setRecoveryInfo={ setRecoveryInfo }
            setStepIndex={ setStepIndex}
            appContext={ appContext } />,
        1: <VerificationCode
            recoveryInfo={ recoveryInfo } 
            setStepIndex={ setStepIndex }
            setRecoveryInfo={ setRecoveryInfo }
            handleCancelValidation={ handleCancelValidation }
            appContext={ appContext } />
    }

    const codeRecoveryErrorMessage = () => {
        return (
            <div className={ styles.error_modal }>
                <div className={ styles.wrapper }>
                    <p className={ styles.title }>Has superado el l&iacute;mite de envios.</p>
                    <p>Si necesitas reenviarlo, por favor intentalo en una hora.</p>
                    <div className={ styles.buttons }>
                        <button onClick={() => appContext.setModalFlag(false)}>Entendido</button>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        appContext.setModalContent(codeRecoveryErrorMessage);
    }, [])

    const renderLoading = () => appContext.loadingData.flag && (
        <div className={ stylesCandidate.loading_container }>
            <div className={ stylesCandidate.spinner_container }>
                <div className={ stylesCandidate.spinner }></div>
                <p>{ appContext.loadingData.loadingLabel }</p>
            </div>
        </div>
    )

    return (
        <div 
            className = { 
                appContext.modalFlag ? 
                ( ( appContext.isDesktopOrLaptop ? styles.modal_wrapper : styles.modal_wrapper_mobile ) + " " + styles.modal_active) 
                : ( appContext.isDesktopOrLaptop ? styles.modal_wrapper : styles.modal_wrapper_mobile ) 
            }
        >
            { renderLoading() }
            { appContext.modalFlag && appContext.modal }
            <Header />
            <div className={ appContext.isDesktopOrLaptop ? styles.pass_recovery : styles.pass_recovery_mobile }>
                {
                    appContext.notification
                }
                <div className={ styles.wrapper }>
                    {
                        pageSteps[stepIndex]
                    }
                </div>
            </div>
            { appContext.isDesktopOrLaptop && <Footer /> }
        </div>
    )
}

export default PasswordRecovery