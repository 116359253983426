import styles from './footer.module.scss';

import {ReactComponent as FacebookIcon } from '../../../assets/icons/facebook_icon.svg';
import {ReactComponent as InstagramIcon } from '../../../assets/icons/instagram_icon.svg';
import {ReactComponent as LinkedInIcon } from '../../../assets/icons/linkedin_icon.svg';
import {ReactComponent as TwitterIcon } from '../../../assets/icons/x_logo.svg';

import appStoreImg from '../../../assets/img/appstore.png';
import googlePlayImg from '../../../assets/img/googleplay.png';


const Footer = () => {
    return (
        <div className={ styles.footer }>
            <div className={ styles.wrapper }>
                <div className={ styles.footer_row }>
                    <div className={ styles.stratis_info }>
                        <a href="https://www.mystratis.com" target="_blank">
                            <img src="https://www.mystratis.com/wp-content/themes/stratis/images/logo.svg" alt="Stratis" />
                        </a>
                        <p>
                            En Stratis ofrecemos igualdad de oportunidades. Todos los solicitantes calificados recibirán consideración para el empleo 
                            sin distinción de raza, color, edad, religión, sexo, orientación sexual, expresión e identidad de género o cualquier otra 
                            característica protegida por la ley federal, estatal o local.
                        </p>
                        <button>Contáctanos</button>
                    </div>
                    <div className={ styles.links }>
                        <div className={ styles.link_column}>
                            <p className={ styles.title }>Servicios</p>
                            <a href="">IT Services</a>
                            <a href="">Business Transformation</a>
                            <a href="">User Experience</a>
                            <a href="">Change Management</a>
                            <a href="">Process Improvement</a>
                        </div>
                        <div className={ styles.link_column}>
                            <p className={ styles.title }>Stratis</p>
                            <a href="">Acerca de nosotros</a>
                            <a href="">Misión y Visión</a>
                            <a href="">Nuestros Valores</a>
                            <a href="">Casos de éxito</a>
                        </div>
                        <div className={ styles.link_column}>
                            <p className={ styles.title }>Comunidad</p>
                            <a href="">Artículos</a>
                            <a href="">Facebook</a>
                            <a href="">Twitter</a>
                            <a href="">Instagram</a>
                            <a href="">LinkedIn</a>
                        </div>
                    </div>
                </div>
                <hr />
                <div className={ styles.footer_row + " " + styles.mobile_inverted }>
                    <div className={ styles.stratis_info }>
                        <p>Politicas de Privacidad</p>
                        <p>&copy; 2022 Stratis. Todos los derechos reservados</p>
                    </div>
                    <div className={ styles.links }>
                        <div className={ styles.link_column + " " + styles.mobile_fill_width }>
                            <p className={ styles.title }>Síguenos en</p>
                            <div className={ styles.icons_row }>
                                <div className={ styles.icon_container}>
                                    <a href="https://www.facebook.com/mystratis" target="_blank">
                                        <FacebookIcon />
                                    </a>
                                </div>
                                <div className={ styles.icon_container}>
                                    <a href="https://www.instagram.com/my.stratis/?hl=es-la" target="_blank">
                                        <InstagramIcon />
                                    </a>
                                </div>
                                <div className={ styles.icon_container}>
                                    <a href="https://www.linkedin.com/company/mystratis/" target="_blank">
                                        <LinkedInIcon />
                                    </a>
                                </div>
                                <div className={ styles.icon_container}>
                                    <a href="https://twitter.com/mystratis" target="_blank">
                                        <TwitterIcon />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={ styles.link_column + " " + styles.mobile_fill_width}>
                            <p className={ styles.title }>Descarga <span>eStratis</span></p>
                            <div className={styles.icons_row + " " + styles.fixed_height}>
                                    <img src={ appStoreImg } alt="" />
                                    <img src={ googlePlayImg } alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;