import { appApi, createJsonAccessToken } from "./apiInstance";

const apiUrl = process.env.REACT_APP_GATEWAY_URL;
const mainContext = 'user';
const module = 'auth';

export const doLogin = request => appApi(apiUrl).post(`${mainContext}/${module}/login`,request);
export const validateToken = accessToken => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/${module}/validate-accessToken?value=${accessToken}`);
export const validateEmailUsed = email => appApi(apiUrl).get(`${mainContext}/byEmail?email=${email}`);
export const resendConfirmationCode = username => appApi(apiUrl).post(`${mainContext}/${module}/resendConfirmationCode?username=${username}`);
export const signUp = request => appApi(apiUrl).post(`${mainContext}/${module}/signUp`,request);
export const confirmSignUp = (username, confirmationCode) => appApi(apiUrl).post(`${mainContext}/${module}/confirmSignUp?username=${username}&confirmationCode=${confirmationCode}`);
export const forgotPassword = email => appApi(apiUrl).post(`${mainContext}/${module}/forgotPassword?email=${email}`);
export const confirmForgotPassword = request => appApi(apiUrl).post(`${mainContext}/${module}/confirmForgotPassword`, request);