import { 
    useContext,
    useEffect,
    useState, 
} from 'react';
import styles from '../../candidateInfo.module.scss';

import addIcon from '../../../../../assets/icons/add_circle.svg';
import pencilIcon from '../../../../../assets/icons/blue_pencil.svg';
import trashIcon from '../../../../../assets/icons/trash.svg';

import { AppContext } from '../../../../../router/approuter';
import { selectEmtpy, yearsHardCode, yearsOrderAsc, yearsOrderDesc } from '../../../../core/hardcode';
import { CertificationModal } from './certification_modal';
import { objEquals, setEndAndValidityDates, sorterDate } from '../../../../core/helper';
import { ModalDelete } from '../../../../components/modal/modal_delete';
import { CandidateContext } from '../../context';
import { deleteCourse, getCourses } from '../../../../api/courseClient';
import { getCatalog } from '../../../../api/catalogClient';

const Certifications = ({
    resumeViewFlag,
    renderBackButton
}) => {

    const appContext = useContext(AppContext)

    const [catStudyLevel, setCatStudyLevel] = useState([]);

    const {
        setCertification,
        cleanCertification,
        certifications, 
        setCertifications 
    } = useContext( CandidateContext );

    const getCertifications = catStudyLevel => {
        appContext.showLoading(true, 'Cargando', styles.no_scroll);
        getCourses(appContext.userData.userProfile.talentId, appContext.userData.token).then((response) => {
            if( response.data && response.data.code === 201 && response.data.result ) {
                let certificationsTmp = [];
                response.data.result.map( certification => {
                    const ceWithDates = setSelectDates(certification);
                    ceWithDates.startDateParsed = ceWithDates.startDate ? `${ceWithDates.fiMes.description} ${ceWithDates.fiAnio.description}` : 'No proporcionado';
                    ceWithDates.endDateParsed =  (ceWithDates.endDate ? `${ceWithDates.ftMes.description} ${ceWithDates.ftAnio.description}` : "En curso")
                    ceWithDates.validityParsed =  (ceWithDates.validityDate ? `${ceWithDates.fvMes.description} ${ceWithDates.fvAnio.description}` : "Sin vigencia")
                    const studyLevel = catStudyLevel.find(s => s.id === certification.typeId)
                    ceWithDates.type = studyLevel && studyLevel.id ? { id: studyLevel.id, description: studyLevel.optionName } : selectEmtpy
                    certificationsTmp.push(ceWithDates);
                })
                const certificationsCurrents = certificationsTmp.filter( ce => !ce.validityDate );
                let certificationsLast = certificationsTmp.filter( ce => ce.validityDate !== null );
                certificationsLast = [ ...certificationsLast.sort((a, b) => sorterDate((a.ftanio ? a.ftAnio.id : -1), (a.ftMes ? a.ftMes.id : -1), (b.ftAnio ? b.ftAnio.id : -1) , (b.ftMes ? b.ftMes.id : -1), yearsOrderAsc)) ];
                certificationsTmp = [ ...certificationsCurrents ]
                certificationsTmp = [ ...certificationsTmp, ...certificationsLast ]
                setCertifications(certificationsTmp);
                appContext.showLoading(false, '', styles.no_scroll);
            }
        }).catch((resp) => {
            appContext.showLoading(false, '', styles.no_scroll);
            console.log(resp)
        })
    }

    const deleteCertification = certification => {
        deleteCourse(appContext.userData.userProfile.talentId, certification.courseId, appContext.userData.userProfile.id, appContext.userData.token).then((response) => {
            if( response.data && response.data.code === 201 && response.data.result ) {
                setCertifications( certifications.filter(ce => !objEquals(ce, certification) ) )
            }
        }).catch(() => {

        })
    }

    const setSelectDates = ce => {
        const yearHardCode1 = yearsHardCode(yearsOrderDesc);
        const yearHardCode2 = yearsHardCode(null);
        return setEndAndValidityDates(ce, yearHardCode1, yearHardCode2);
    }

    const handleOnClickShowModal = (certification, index) => {
        if ( certification ) {
            setCertification({
                ...certification,
                index: index
            });
        } else if (!certification) {
            cleanCertification();
        }
        appContext.setModalContent( <CertificationModal catStudyLevel={ catStudyLevel } /> )
        appContext.setModalFull(true);
        appContext.setModalFlag(true)
    }

    const handleOnClickRemove = certification => {
        if( certification.courseId ) {
            deleteCertification(certification);
        } else {
            setCertifications( certifications.filter(ce => !objEquals(ce, certification)) )
        }
        appContext.setModalFlag(false);
    }

    useEffect(() => {
        getCatalog(1000010053, 0 ,100, '', appContext.userData.token).then((response) => {
            const catStudyLevel = [];
            response.data.result.content.map( s => catStudyLevel.push({ id: s.catalogItemId, optionName: s.valueItem }) )
            setCatStudyLevel(catStudyLevel);
            getCertifications(catStudyLevel)
        }).catch((response) => {
            console.log(response);
        });
    }, []);

    // useEffect(() => {
    //     getCertifications(certifications.sort((a, b) => sorterDate(a.ftAnio.id, a.ftMes.id, b.ftAnio.id , b.ftMes.id, yearsOrderAsc)));
    // }, [certifications])

    const showDeleteModal = index => {
        const certification = certifications[index];
        appContext.setModalContent(
            <ModalDelete
                title={ `${certification.name} - ${certification.school}` }
                subtitle={`¿Quieres borrar este curso o certificación de tu perfil?
                    Esta acción no podrá revertirse.`}
                onClick={ () => handleOnClickRemove(certification) }
                onClickCancel={ () => appContext.setModalFlag(false) }
            />
        )
        appContext.setModalFull(false);
        appContext.setModalFlag(true);
    }

    const renderCertifications = () => (
		certifications && certifications.map((ce, index) => (
            <div key={index} className={ styles.list_container }>
                <div className={ styles.list_items }>
                    <div className={ styles.item_grouped }>
                        <label>{ `${ce.name} - ${ce.school}` }</label>
                        <div className={ styles.action_container }>
                            <img src={ pencilIcon } alt="" onClick={ () => handleOnClickShowModal(ce, index) } />
                            <img src={ trashIcon } alt="" onClick={ () => showDeleteModal(index) } />
                        </div>
                    </div>
                    <div className={ styles.certification_info }>
                        <div className={ styles.info_container}>
                            <label>Número de certificado:</label>
                            <p>{ ce.certificateNumber || "Sin número de certificado"}</p>
                        </div>
                        <div className={ styles.info_container}>
                            <label>Periodo:</label>
                            <p>{ `${ce.startDateParsed} - ${ce.endDateParsed}` }</p>
                        </div>
                        <div className={ styles.info_container}>
                            <label>Fecha de vigencia:</label>
                            <p>{ ce.validityParsed }</p>
                        </div>
                    </div>
                </div>
            </div>
        ))
	);

    const title = 'Cursos y Certificaciones';
    const renderTitle = () => (<p className={ styles.title }>{ title }</p>);

    return (
        <div className={ styles.form_container }>
            { appContext.isDesktopOrLaptop ? renderTitle() : ( resumeViewFlag ? renderTitle() : renderBackButton(title)) }
            <div className={ styles.form_wrapper }>
                <div className={ styles.form_row + " " + styles.no_gap }>
                    <p className={ styles.subtitle }>
                        Agrega informaci&oacute;n de los cursos o certificaciones que has tomado recientemente. 
                    </p>
                </div>
                {
                    renderCertifications()
                }
                <div className={ styles.form_row + " " + styles.padded }>
                    <button className={ styles.add_info } onClick={() => handleOnClickShowModal(null)}>
                        Agregar curso o certificación
                        <img src={ addIcon } alt="" />
                    </button>
                </div>
            </div>
        </div>
    )
}
export default Certifications;