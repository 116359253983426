import { appApi, createJsonAccessToken } from './apiInstance';

const apiUrl = process.env.REACT_APP_GATEWAY_URL;

const mainContext = 'talent';
const module = 'education';

export const newEducation = (talentId, workExperience, userId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).post(`${mainContext}/${talentId}/${module}/new?userId=${userId}`, { ...workExperience, description: 'No se proporciono descripción' });

export const editEducation = (talentId, workExperience, userId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).put(`${mainContext}/${talentId}/${module}/edit?userId=${userId}`, workExperience);

export const getEducations = (talentId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/${talentId}/${module}`);

export const deleteEducation = (talentId, id, userId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).delete(`${mainContext}/${talentId}/${module}/${id}/delete?userId=${userId}`);